import type { Direction } from '@lighthouse/core'
import { BACKGROUND_TYPE, DIRECTION, EDGE_MODE, FLEX_ALIGN, HEIGHT_RULE, MAX_CONTAINER_WIDTH_SIZE } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useMediaQueryRemBase } from '../../../../hooks/useMediaQueryRemBase'
import {
    combineBackgroundStyle,
    getBackgroundStyle,
    getBorderStyle,
    getRadiusStyle,
    getShadowStyle,
    getVeinsStyle
} from '../../../../utils/design'
import { FLOW_LAYOUT_NODE_HEIGHT } from '../../../../utils/page'
import { useFillPickerContext } from '../../../FillPicker'
import type { FlowContainerContextValue } from '../../Context'
import { FlowContainerProvider, useFlowContainerContext, useFlowLayoutContext } from '../../Context'
import { useContainerHeight } from '../../hooks/useContainerHeight'
import { SIZE_OPTIONS } from '../../hooks/useContainerWidth'
import { ContainerOutline } from '../../Sortable/ContainerOutline'
import { useResizeEventsContext, useSortableContext, useSortableMonitor } from '../../Sortable/Context'
import type { FlowLayoutContainerNode, NodeRenderProps } from '../../types'
import { Padding } from '../Padding'
import { Toolbar } from './Toolbar'

type StyledProps = {
    direction?: Direction
    mobileAdaptive?: boolean
    isMobile: boolean
}

type BoxProps = {
    disabled?: boolean
}

const ROW_CSS = css`
    flex-direction: row;
    justify-content: var(--container-align-x);
    align-items: var(--container-align-y);
    & > & {
        flex-grow: 1;
    }
`
const COLUMN_CSS = css`
    flex-direction: column;
    justify-content: var(--container-align-y);
    align-items: var(--container-align-x);
`

const StyledContainerNode = styled.div<StyledProps>`
    flex: 1;
    position: relative;
    display: flex;
    padding-top: var(--container-padding-top);
    padding-right: var(--container-padding-right);
    padding-bottom: var(--container-padding-bottom);
    padding-left: var(--container-padding-left);

    ${({ direction, mobileAdaptive, isMobile }) =>
        direction === DIRECTION.horizontal
            ? [
                  ROW_CSS,
                  isMobile &&
                      mobileAdaptive &&
                      css`
                          ${COLUMN_CSS}
                      `
              ]
            : COLUMN_CSS}
`

const OutlineWrapper = styled.div`
    position: absolute;
    pointer-events: none;
    display: none;
`
const GradientBorderWrapper = styled.div`
    position: absolute;
    inset: 0;
    pointer-events: none;
`

const StyledBox = styled.div<BoxProps>`
    display: flex;
    position: relative;
    ${({ disabled }) => [
        !disabled &&
            css`
                /* 子级容器hover */
                &:not([data-selected='true']):not(:has(div[data-type='container']:hover)):hover
                    > ${OutlineWrapper},
                    /* 子级被选中 */
                    &:has(> div > div[data-type='node'][data-selected='true'])
                    > ${OutlineWrapper},
                    &:has(> div > div[data-type='container'][data-selected='true'])
                    > ${OutlineWrapper} {
                    display: block;
                }
            `
    ]}

    &[data-box-selection='true'], &[data-selected='true'] {
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }

    &[data-over='true'] ${StyledContainerNode} {
        background-color: var(--color-main);
    }

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutContainerNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutContainerNode
    unstyled?: boolean
    disabled?: boolean
    onDataDrawerVisible?: (val: boolean) => void
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
}

export const ContainerNode = forwardRef<HTMLDivElement, FlowLayoutContainerNodeProps>((props, ref) => {
    const { data, style, unstyled, disabled: propDisabled, onDataDrawerVisible, nodeRender, children, ...rest } = props
    const {
        direction = DIRECTION.vertical,
        size = MAX_CONTAINER_WIDTH_SIZE.unlimited,
        heightRule = HEIGHT_RULE.AUTO,
        crop = true,
        mobileAdaptive = false,
        gap = 0,
        alignX = FLEX_ALIGN['flex-start'],
        alignY = FLEX_ALIGN['flex-start'],
        padding,
        background,
        veins,
        radius,
        border,
        shadow
    } = data.data
    const [borderRadiusTopLeft = 0, borderRadiusTopRight = 0, borderRadiusBottomLeft = 0, borderRadiusBottomRight = 0] = radius || []
    const [paddingLeft = 0, paddingTop = 0, paddingRight = 0, paddingBottom = 0] = padding || []

    const {
        type: borderType,
        color: borderColor,
        gradient: borderGradient,
        mode: borderMode,
        all: borderAll,
        each: borderEach
    } = border ?? {}
    const [borderLeft = 0, borderTop = 0, borderRight = 0, borderBottom = 0] =
        borderMode === EDGE_MODE.each ? borderEach || [] : Array.from({ length: 4 }).map(() => borderAll || 0)
    const isGradientBorder = borderType === BACKGROUND_TYPE.gradient

    const {
        highlight,
        isMobile,
        rows,
        disabled: globalDisabled,
        nodeRender: globalNodeRender = React.Fragment,
        parseBackgroundVariableImage
    } = useFlowLayoutContext()
    const NodeRender = nodeRender ?? globalNodeRender

    const disabled = propDisabled ?? globalDisabled

    const { ref: nodeRef, height } = useContainerHeight()
    const withBorderHeight = height + borderTop + borderBottom

    const { selectedId, boxSelectionIds, activeId } = useSortableMonitor()
    const { parentId } = useSortableContext()
    const { isResizing } = useResizeEventsContext()
    const {
        nodeUnitWidth: parentNodeUnitWidth,
        direction: parentDirection,
        mobileAdaptive: parentMobileAdaptive
    } = useFlowContainerContext()
    const width = parentNodeUnitWidth * (parentDirection === DIRECTION.horizontal && isMobile && parentMobileAdaptive ? 12 : data.width)
    const contentWidth =
        size === MAX_CONTAINER_WIDTH_SIZE.unlimited
            ? width - borderLeft - borderRight
            : Math.min(SIZE_OPTIONS[size], width - borderLeft - borderRight)
    const baseRem = useMediaQueryRemBase()
    const length = data.children?.length ?? 1
    const contextValue: FlowContainerContextValue = useMemo(() => {
        return {
            fullWidth: width,
            contentWidth,
            nodeUnitWidth:
                (contentWidth -
                    (direction === DIRECTION.horizontal && !(isMobile && mobileAdaptive) ? (length - 1) * gap * baseRem : 0) -
                    paddingLeft * baseRem -
                    paddingRight * baseRem) /
                rows,
            size,
            direction,
            mobileAdaptive,
            gap,
            alignX,
            alignY,
            padding
        }
    }, [
        width,
        contentWidth,
        direction,
        isMobile,
        mobileAdaptive,
        length,
        gap,
        baseRem,
        paddingLeft,
        paddingRight,
        rows,
        size,
        alignX,
        alignY,
        padding
    ])

    const nodeHeight = useMemo(() => {
        switch (heightRule) {
            case HEIGHT_RULE.FILL: {
                return '100%'
            }
            case HEIGHT_RULE.FIXED: {
                return data.height && data.height * FLOW_LAYOUT_NODE_HEIGHT
            }
            default: {
                break
            }
        }
    }, [data.height, heightRule])

    const isDragging = !!activeId
    const isSelected = selectedId === data.id
    const isBoxSelection = !!boxSelectionIds?.includes(data.id)

    const { palettes } = useFillPickerContext()

    return (
        <StyledBox
            data-type="container"
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            data-selected={!unstyled && !disabled && isSelected}
            data-box-selection={!unstyled && !disabled && isBoxSelection}
            disabled={disabled || isResizing}
            ref={mergeRefs([ref, nodeRef])}
            style={{
                visibility: width === 0 ? 'hidden' : 'visible',
                width: unstyled ? '100%' : `${width.toFixed(2)}px`,
                height: unstyled ? '100%' : nodeHeight,
                flexShrink: parentDirection !== DIRECTION.horizontal && heightRule === HEIGHT_RULE.FILL ? undefined : 0,
                flexGrow: parentDirection !== DIRECTION.horizontal && heightRule === HEIGHT_RULE.FILL ? 1 : undefined,
                flexBasis: parentDirection !== DIRECTION.horizontal && heightRule === HEIGHT_RULE.FILL ? '100%' : undefined,
                minHeight: data.children && data.children.length > 0 ? undefined : 100,
                '--node-width-unit': `${contextValue.nodeUnitWidth}px`,
                '--container-direction': direction,
                '--container-align-x': alignX,
                '--container-align-y': alignY,
                '--container-gap': `${gap}rem`,
                '--container-padding-left': `${paddingLeft}rem`,
                '--container-padding-top': `${paddingTop}rem`,
                '--container-padding-right': `${paddingRight}rem`,
                '--container-padding-bottom': `${paddingBottom}rem`,
                ...combineBackgroundStyle([
                    getBackgroundStyle(background, parseBackgroundVariableImage, palettes),
                    getVeinsStyle(veins, palettes)
                ]),
                // ...getRadiusStyle(radius),
                ...getBorderStyle(border, palettes),
                ...getShadowStyle(shadow, palettes),
                ...style
            }}
            {...rest}
        >
            <FlowContainerProvider value={contextValue}>
                <NodeRender node={data} disabled={propDisabled}>
                    {children}
                    <Padding
                        containerId={data.id}
                        data-type="padding-top"
                        data-relative-id={data.id}
                        data-actual-relative-id={data.virtual ? parentId : undefined}
                        data-virtual-parent-id={data.virtual ? data.id : undefined}
                        data-highlight={!!highlight?.[data.id]?.paddingY}
                        style={{
                            display: isDragging && direction === DIRECTION.horizontal ? 'none' : undefined,
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${paddingTop}rem`
                        }}
                    />
                    <Padding
                        containerId={data.id}
                        data-type="padding-right"
                        data-relative-id={data.id}
                        data-actual-relative-id={data.virtual ? parentId : undefined}
                        data-virtual-parent-id={data.virtual ? data.id : undefined}
                        data-highlight={!!highlight?.[data.id]?.paddingX}
                        style={{
                            display: isDragging && direction === DIRECTION.vertical ? 'none' : undefined,
                            top: 0,
                            right: 0,
                            width: `${paddingRight}rem`,
                            height: '100%'
                        }}
                    />
                    <Padding
                        containerId={data.id}
                        data-type="padding-bottom"
                        data-relative-id={data.id}
                        data-actual-relative-id={data.virtual ? parentId : undefined}
                        data-virtual-parent-id={data.virtual ? data.id : undefined}
                        data-highlight={!!highlight?.[data.id]?.paddingY}
                        style={{
                            display: isDragging && direction === DIRECTION.horizontal ? 'none' : undefined,
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: `${paddingBottom}rem`
                        }}
                    />
                    <Padding
                        containerId={data.id}
                        data-type="padding-left"
                        data-relative-id={data.id}
                        data-actual-relative-id={data.virtual ? parentId : undefined}
                        data-virtual-parent-id={data.virtual ? data.id : undefined}
                        data-highlight={!!highlight?.[data.id]?.paddingX}
                        style={{
                            display: isDragging && direction === DIRECTION.vertical ? 'none' : undefined,
                            top: 0,
                            left: 0,
                            width: `${paddingLeft}rem`,
                            height: '100%'
                        }}
                    />
                </NodeRender>

                {/* 容器渐变边框 */}
                {isGradientBorder && borderGradient && (
                    <GradientBorderWrapper>
                        <svg viewBox={`0 0 ${width} ${withBorderHeight}`} xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="borderGradient" gradientTransform="rotate(0)">
                                    {borderGradient.stops.map(stop => (
                                        <stop key={stop.percentage} offset={`${stop.percentage}%`} stopColor={stop.color} />
                                    ))}
                                </linearGradient>
                            </defs>
                            <path
                                fill="none"
                                strokeWidth={borderLeft || borderTop || borderRight || borderBottom}
                                stroke="url(#borderGradient)"
                                d={`m0, ${borderRadiusTopLeft}
                                    c0, ${-borderRadiusTopLeft / 2} ${
                                    borderRadiusTopLeft / 2
                                }, ${-borderRadiusTopLeft}, ${borderRadiusTopLeft} ${-borderRadiusTopLeft}
                                    h${width - borderRadiusTopLeft - borderRadiusTopRight}
                                    c${borderRadiusTopRight / 2}, 0 ${borderRadiusTopRight}, ${
                                    borderRadiusTopRight / 2
                                } ${borderRadiusTopRight}, ${borderRadiusTopRight}
                                    v${withBorderHeight - borderRadiusTopRight - borderRadiusBottomRight}
                                    c0, ${borderRadiusBottomRight / 2}, ${
                                    -borderRadiusBottomRight / 2
                                }, ${borderRadiusBottomRight} ${-borderRadiusBottomRight}, ${borderRadiusBottomRight}
                                    h-${width - borderRadiusBottomLeft - borderRadiusBottomRight}
                                    c${-borderRadiusBottomLeft / 2}, 0, ${-borderRadiusBottomLeft}, ${
                                    -borderRadiusBottomLeft / 2
                                } ${-borderRadiusBottomLeft}, ${-borderRadiusBottomLeft}
                                    z`}
                                // d={`m0, ${borderRadiusTopLeft}
                                //     q0, ${-borderRadiusTopLeft} ${borderRadiusTopLeft}, ${-borderRadiusTopLeft}
                                //     h${_width - borderRadiusTopLeft - borderRadiusTopRight}
                                //     q${borderRadiusTopRight}, 0 ${borderRadiusTopRight}, ${borderRadiusTopRight}
                                //     v${_height - borderRadiusTopRight - borderRadiusBottomRight}
                                //     q0, ${borderRadiusBottomRight}, ${-borderRadiusBottomRight}, ${borderRadiusBottomRight}
                                //     h-${_width - borderRadiusBottomLeft - borderRadiusBottomRight}
                                //     q${-borderRadiusBottomLeft}, 0, ${-borderRadiusBottomLeft}, ${-borderRadiusBottomLeft}
                                //     z`}
                            />
                        </svg>
                    </GradientBorderWrapper>
                )}

                {/* 交互时指示边框 */}
                {disabled ? null : (
                    <OutlineWrapper style={{ left: -borderLeft, top: -borderTop, right: -borderRight, bottom: -borderBottom }}>
                        <ContainerOutline width={width} height={withBorderHeight} />
                    </OutlineWrapper>
                )}
            </FlowContainerProvider>

            {isSelected && !unstyled && !disabled && !isDragging && (
                <Toolbar data={data} referenceRef={nodeRef} onDataDrawerVisible={onDataDrawerVisible} />
            )}
        </StyledBox>
    )
})
